.wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 640px;
  margin: 0 auto;
  text-align: center; }

.timer {
  font-size: 48px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: 700;
  color: #0083ff;
  margin-top: 5px; }

.clock {
  margin-top: 30px; }

.clock .column {
  display: inline-block; }

.days {
  display: none; }

.clock .text {
  font-size: 12px;
  font-weight: 900;
  color: #839dbc; }

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
    padding: 0 20px; }
  .timer {
    font-size: 35px; } }

.clear-loading {
  text-align: center;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.spinner {
  width: 100px;
  height: 100px; }

.spinner > span,
.spinner > span:before,
.spinner > span:after {
  content: "";
  display: block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.spinner > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-left-color: transparent;
  -webkit-animation: effect-2 2s infinite linear;
  animation: effect-2 2s infinite linear; }

.spinner > span:before {
  width: 75%;
  height: 75%;
  border-right-color: transparent; }

.spinner > span:after {
  width: 50%;
  height: 50%;
  border-bottom-color: transparent; }

@-webkit-keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 1024px) {
  .timer {
    font-size: 30px; } }
